import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputField from '../components/InputField';
import Button from '../components/Button';
import styles from './Signup.module.css';
import { registerUser, verifyUser } from '../services/authService';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


const Signup = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showVerification, setShowVerification] = useState(false);  //controla a exibição do campo de verificação
    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);  //controle de estado do botão de subimit
    const [isVerifying, setIsVerifying] = useState(false);  //controla de estado do botão de verificação
    const [verificationSuccessMessage, setVerificationSuccessMessage] = useState('');  //msg após verificação
    const [showPassword, setShowPassword] = useState(false);  //p/ alternar visibilidade da senha
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);  //p/ alternar visibilidade da confirmação de senha
    const navigate = useNavigate();

    const initialValues = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Nome é obrigatório'),  // Validação do nome
        email: Yup.string().email('Email inválido').required('Email é obrigatório'),
        password: Yup.string().required('Senha é obrigatória').min(6, 'A senha deve ter no mínimo 6 caracteres'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas devem coincidir').required('Confirmação de senha é obrigatória'),
    });

    const handleSubmit = async (values, { setSubmitting, setErrors }) => {
        setIsSubmitting(true);  // Desabilita o botão de cadastro e os campos
        try {
            const response = await registerUser(values); // Tente cadastrar o usuário
            console.log('Cadastro bem-sucedido', response); // Verifique o que é retornado
            setEmail(values.email);
            setSuccessMessage('Cadastro bem-sucedido! Verifique seu e-mail para confirmar sua conta.');
            setErrorMessage('');
            setShowVerification(true);  // Mostra o campo de verificação
        } catch (error) {
            console.error('Erro ao cadastrar usuário:', error.response || error.message);
            setErrors({ apiError: 'Falha no cadastro, tente novamente.' });
            setErrorMessage('Falha no cadastro, tente novamente.');
        }
        setIsSubmitting(false);  // Habilita o botão de cadastro (opcional)
    };

    const handleCodeVerification = async (e) => {
        e.preventDefault();
        setIsVerifying(true);  //desabilita o botão de verificação
        setTimeout(async () => {
            try {
                await verifyUser(email, code);  // Verifica o código
                setVerificationSuccessMessage('Código verificado com sucesso! Conta confirmada.');
                setErrorMessage('');

                setTimeout(() => {
                    navigate('/dashboard');
                }, 3000);  // Redireciona após 2 segundos
            } catch (error) {
                setErrorMessage('Falha ao verificar o código, tente novamente.');
                setVerificationSuccessMessage('');
            }
            setIsVerifying(false);  //habilita o botão de verificação novamente
        }, 2000);
    };


    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Cadastro</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors }) => (
                    <Form>
                        <InputField
                            name="name"
                            label="Nome"  // Campo de entrada para o nome
                            type="text"
                            disabled={isSubmitting || showVerification}  //desabilita os campos após o cadastro
                        />
                        <InputField
                            name="email"
                            label="Email"
                            type="email"
                            disabled={isSubmitting || showVerification}  //desabilita os campos após o cadastro
                        />
                        {/* Campo de senha com toggle de visibilidade */}
                        <div className={styles.passwordContainer}>
                            <InputField
                                name="password"
                                label="Senha"
                                type={showPassword ? 'text' : 'password'}
                                disabled={isSubmitting || showVerification}
                            />
                            <span className={styles.togglePassword} onClick={togglePasswordVisibility}>
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </span>
                        </div>

                        {/* Campo de confirmar senha com toggle de visibilidade */}
                        <div className={styles.passwordContainer}>
                            <InputField
                                name="confirmPassword"
                                label="Confirmar Senha"
                                type={showConfirmPassword ? 'text' : 'password'}
                                disabled={isSubmitting || showVerification}
                            />
                            <span className={styles.togglePassword} onClick={toggleConfirmPasswordVisibility}>
                                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                            </span>
                        </div>

                        {errors.apiError && <div className={styles.error}>{errors.apiError}</div>}
                        {successMessage && <div className={styles.success}>{successMessage}</div>}

                        {!showVerification && (  //ocult o botão de cadastro após sucesso
                            <Button type="submit" label={isSubmitting ? "Cadastrando..." : "Cadastrar"} disabled={isSubmitting} />
                        )}
                    </Form>
                )}
            </Formik>

            {showVerification && (
                <form onSubmit={handleCodeVerification}>
                    <h3 className={styles.verificationMessage}>Insira o código de verificação enviado para o seu
                        e-mail</h3>
                    <input
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="Código de Verificação"
                        disabled={isVerifying}  //desabilita o campo se estiver carregando
                    />
                    <Button type="submit" label={isVerifying ? "Verificando..." : "Verificar Código"}
                            disabled={isVerifying}/>
                </form>
            )}

            {verificationSuccessMessage && (  //show a mensagem de sucesso após a verificação
                <p className={styles.success}>{verificationSuccessMessage}</p>
            )}

            {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        </div>
    );
};

export default Signup;
